//
// _menu.scss
// 

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {
            display: none;
        }

        &.mm-show {
            display: block
        }
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}


.vertical-menu{
    width: $sidebar-width;
    z-index: 1004;
    background: $sidebar-bg;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    border-right: 1px solid $sidebar-bg;
    transition: all 0.2s;

    .vertical-menu-btn {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        margin-top: 10px;
    }

    @media (min-width: 992px) {
        &::before{
            content: "";
            position: absolute;
            right: -45px;
            width: 45px;
            height: 45px;
            top: 0;
            background-color: $sidebar-bg;
        }
    }
}

.topnav-hamburger{
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger-icon {
    width: 20px;
    height: 14px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    span {
        background-color: $sidebar-dark-menu-item-color;
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 2px;
        display: block;
        left: 0px;

        &:nth-child(1) {
            top: 0;
            width: 80%;
        }

        &:nth-child(2) {
            top: 6px;
        }

        &:nth-child(3) {
            bottom: 0;
            width: 60%;
        }
    }

    .vertical-menu-btn:hover &:not(.open){
        span {

            &:nth-child(1) {
                top: -1px;
            }

            &:nth-child(3) {
                bottom: -1px;
            }
        }
    }

    &.open {
        transform: rotate(-90deg);

        span {

            &:nth-child(1) {
                left: 1px;
                top: 5px;
                width: 20px;
                transform: rotate(90deg);
                transition-delay: 150ms;
            }

            &:nth-child(2) {
                left: 3px;
                top: 13px;
                width: 10px;            
                transform: rotate(45deg);
                transition-delay: 50ms;
            }

            &:nth-child(3) {
                left: 9px;
                top: 13px;
                width: 10px;            
                transform: rotate(-45deg);
                transition-delay: 100ms;
            }
        }
    }
}



.main-content {
    margin-left: $sidebar-width;
    // overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

.sidebar-menu-scroll {
    height: calc(100% - calc(#{$header-height} + 26px));
    margin-top: calc(#{$header-height} + 26px);
}


#sidebar-menu {
    padding: 0px 0 30px 0;

    .mm-active {
        >.has-arrow {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F0140";
            font-family: 'Material Design Icons';
            display: block;
            transition: transform .2s;
            font-size: 1rem;
            position: absolute;
            right: 20px;
            top: 9px;
        }

        + ul .has-arrow {
            &:after {
                right: 25px;
                top: 7px;
            }
        }
    }

    .metismenu {
        .menu-title {
            &::after {
                display: none;
            }
        }
        .menu-item {
            margin-left: 6px;
        }
    }

    ul {
        li {
            a {
                display: flex;
                padding: .7rem 1.5rem;
                color: $sidebar-menu-item-color;
                position: relative;
                font-weight: $font-weight-medium;
                transition: all .4s;
                font-size: 14.4px;
                font-family: $font-family-secondary;
                align-items: center;

                .nav-icon {
                    width: 18px;
                    height: 18px;
                    display: inline-block;
                    min-width: 1.5rem;
                    vertical-align: middle;
                    fill: $sidebar-menu-item-icon-color;
                    transition: all 0.3s;
                    top: -1px;
                }
                

                &:hover {
                    color: $sidebar-menu-item-hover-color;
                    .nav-icon {
                        fill: $sidebar-menu-item-hover-color;
                    }
                }
            }

            .badge {
                margin-left: auto;
            }

            ul.sub-menu {
                padding: 0;

                li {

                    a {
                        padding: .5rem 1.5rem .5rem 3.4rem;
                        font-weight: 400;
                        color: $sidebar-menu-sub-item-color;
                        margin: 0;
                        background-color: transparent;
                        font-size: 14.4px;
                        font-family: $font-family-base;

                        &:hover {
                            color: $sidebar-menu-item-hover-color;

                            &:before{
                                border-color: $primary;
                                background-color: $primary;
                            }
                        }
                    }

                    ul.sub-menu {
                        padding: 0;

                        li {
                            a {
                                padding: .4rem 1.5rem .4rem 4.5rem;

                                &:before{
                                    left: 55px;
                                    top: 15px;
                                }
                            }
                        }
                    }
                }
            }

            &.mm-active {
                color: $sidebar-menu-item-active-color;
            
                > a {
                    color: $sidebar-menu-item-active-color;

                    &::before{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 24px;
                        width: 5px;
                        background-color: $sidebar-menu-item-active-color;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
            
                    .nav-icon {
                        fill: $sidebar-menu-item-active-color !important;
                    }
                }
            
                .mm-show {
                    .mm-active {
                        > a{
                            color: $sidebar-menu-sub-item-active-color !important;
                    
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-title {
    padding: 12px 25px !important;
    letter-spacing: .08em;
    pointer-events: none;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    color: $sidebar-menu-item-color;
    font-weight: $font-weight-semibold;
    opacity: 0.6;
}

@media (max-width: 991.98px) {
    .vertical-menu {
        display: none;
        top: $header-height;

        .sidebar-menu-scroll {
            height: 100%;
            margin-top: 0;
        }

        .navbar-brand-box,
        .vertical-menu-btn {
            display: none;
        }

    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            display: block;
        }
    }
}

// Enlarge menu
body[data-sidebar-size="sm"] {
    @media (min-width: 769px) {
        min-height: 1850px;
    }

    .main-content {
        margin-left: $sidebar-collapsed-width;
    }

    .navbar-brand-box {
        width: $sidebar-collapsed-width !important;
        text-align: center;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: block;
        }
    }

    // topbar
    #page-topbar{
        left: $sidebar-collapsed-width;
        @media (max-width: 991.98px){
            left: 0;
        }
    }

    // Side menu
    .vertical-menu {
        position: absolute;
        width: $sidebar-collapsed-width !important;
        z-index: 1001;
        &::before{
            position: fixed;
            left: $sidebar-collapsed-width;
            top: 0;
        }

        .simplebar-mask,
        .simplebar-content-wrapper {
            overflow: visible !important;
        }

        .simplebar-scrollbar,
        .vertical-menu-btn {
            display: none !important;
        }

        .simplebar-offset {
            bottom: 0 !important;
        }

        // Sidebar Menu
        #sidebar-menu {

            .menu-title,
            .badge{
                display: none !important;
            }

            .nav.collapse {
                height: inherit !important;
            }

            .has-arrow:after{
                transform: rotate(-90deg);
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        padding: 15px 20px;
                        transition: none;
                        display: block;
                        
                        &:hover,
                        &:active,
                        &:focus {
                            color: $sidebar-menu-item-hover-color;
                        }

                        i {
                            font-size: 1.3rem;
                            text-align: center;
                            min-width: 50px;
                            padding-bottom: 0;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }

                        
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }

                        .nav-icon {
                            top: -2px;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &:hover {
                        > a {
                            position: relative;
                            width: calc(180px + #{$sidebar-collapsed-width});
                            color: $primary;
                            background-color: $sidebar-menu-item-active-bg-color;
                            transition: none;
                            box-shadow: inset 0 3px 10px 0 rgba(154,161,171,.2);

                            .nav-icon{
                                fill: $primary;
                            }

                            span {
                                display: inline;
                            }
                        }

                        >ul {
                            display: block;
                            left: $sidebar-collapsed-width;
                            position: absolute;
                            width: 180px;
                            height: auto !important;
                            box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);

                            ul {
                                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
                            }

                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 180px;
                                z-index: 6;
                                color: $sidebar-menu-sub-item-color;

                                &:hover {
                                    color: $sidebar-menu-item-hover-color;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $sidebar-bg;

                    li {
                        &:hover {
                            >ul {
                                display: block;
                                left: 180px;
                                height: auto !important;
                                margin-top: -36px;
                                position: absolute;
                                width: 180px;
                            }
                        }

                        >a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }

                    li.active {
                        a {
                            color: $gray-100;
                        }
                    }
                }
            }

            ul li{
                ul.sub-menu {
                    li a{
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }

        .sidebar-footer{
            display: none;
        }

    }
}

.main-content{
    min-height: 100vh;
    background-color: $body-bg;
    @media (min-width: 992px) {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
}

body[data-layout-mode="dark"] {
    
    #page-topbar{
        background-color: $topnav-dark-bg;
        &.sticky{
            background-color: $gray-dark-200;
            .noti-icon {
                .noti-dot {
                    box-shadow: 0 0 0 3px $gray-dark-200;
                }
            }
        }
    }

    &[data-sidebar="dark"] {
        #sidebar-menu {
            ul {
                li {
                    a {
                        color: $menu-dark-item-color;
    
                        .nav-icon {
                            fill: $menu-dark-item-color;
                        }
        
                        &:hover {
                            color: $menu-dark-item-active-color;
    
                            .nav-icon {
                                fill: $menu-dark-item-active-color;
                            }
                        }
                    }
    
                    ul.sub-menu {
                        li {
    
                            a {
                                color: $menu-dark-item-color;
    
                                &:before{
                                    display: none;
                                }
    
                                &:hover {
                                    color: $menu-dark-item-active-color;
                                    &:before{
                                        background-color: $menu-dark-item-active-color;
                                        border-color: $menu-dark-item-active-color;
                                    }
                                }
    
                            }
                        }
                    }
    
    
                    &.mm-active {
                        color: $menu-dark-item-active-color !important;
                
                        >a {
                            color: $menu-dark-item-active-color !important;
    
                            &::before{
                                background-color: $menu-dark-item-active-color;
                            }
    
                            .nav-icon {
                                fill: $menu-dark-item-active-color !important;
                            }
                        }
    
                        .mm-show {
                            .mm-active {
                                > a{
                                    color: $menu-dark-item-active-color !important;
                            
                                    &:before{
                                        border-color: $menu-dark-item-active-color !important;
                                        background-color: $menu-dark-item-active-color !important;
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    
        .sidebar-footer{
            color: $sidebar-dark-menu-item-color;
            .main-title{
                color: rgba($white, 0.6);
            }
        }

         // Compact
        &[data-sidebar-size="md"] {
            &[data-sidebar="dark"] {
                #sidebar-menu {
                    ul li {
                        &.menu-title{
                            background-color: lighten($gray-dark-200, 6%);
                        }
                    }
                }
            } 
        }
    
        // Enlarge menu
        &[data-sidebar-size="sm"] {
    
            // Side menu
            .vertical-menu {
    
                // Sidebar Menu
                #sidebar-menu {
    
                    > ul {
                        > li {
                            
                            &:hover {
                                > a {
                                    background: lighten($gray-dark-200, 3%);
                                    color: $menu-dark-item-active-color;
                                    .nav-icon{
                                        fill: $menu-dark-item-active-color;
                                    }
                                }
    
                                >ul {
                                    a{
                                        color: $menu-dark-item-color;
                                        &:hover{
                                            color: $menu-dark-item-active-color;
                                        }
                                    }
                                }
                            }
                        }
    
                        ul{
                            background-color: $gray-dark-200
                        }
                        
                    }
                }
            }
        }
    
    
        .menu-title {
            color: $sidebar-dark-menu-item-color;
        }
    }

    .hamburger-icon {
        span {
            background-color:  $menu-dark-item-color;
        }
    }

    .main-content{
        background-color:  darken($gray-dark-200, 5%);
    }
}


body[data-sidebar="light"] {
    background: $sidebar-bg;
}

body[data-sidebar="dark"] {
    background: $sidebar-dark-bg;
    .vertical-menu {
        background: $sidebar-dark-bg;
        border-color: $sidebar-dark-bg;

        .vertical-menu-btn{
            fill: $sidebar-dark-menu-item-color;
        }

        &::before{
            background-color: $sidebar-dark-bg;
        }
    }

  

    #sidebar-menu {
    
        ul {
            li {
                a {
                    color: $sidebar-dark-menu-item-color;

                    .nav-icon {
                        fill: $sidebar-dark-menu-item-icon-color;
                    }
    
                    &:hover {
                        color: $sidebar-dark-menu-item-hover-color;

                        .nav-icon {
                            fill: $sidebar-dark-menu-item-hover-color;
                        }
                    }
                }

                ul.sub-menu {
                    li {

                        a {
                            color: $sidebar-dark-menu-sub-item-color;
                            background-color: transparent;

                            &:before{
                                display: none;
                            }

                            &:hover {
                                color: $sidebar-dark-menu-item-hover-color;
                                &:before{
                                    background-color: $sidebar-dark-menu-item-hover-color;
                                    border-color: $sidebar-dark-menu-item-hover-color;
                                }
                            }

                        }
                    }
                }


                &.mm-active {
                    color: $sidebar-dark-menu-item-active-color !important;
            
                    >a {
                        color: $sidebar-dark-menu-item-active-color !important;

                        &::before{
                            background-color: $sidebar-dark-menu-item-active-color;
                        }

                        .nav-icon {
                            fill: $sidebar-dark-menu-item-active-color !important;
                        }
                    }

                    .mm-show {
                        .mm-active {
                            > a{
                                color: $sidebar-dark-menu-item-active-color !important;
                        
                                &:before{
                                    border-color: $sidebar-dark-menu-item-active-color !important;
                                    background-color: $sidebar-dark-menu-item-active-color !important;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .sidebar-footer{
        color: $sidebar-dark-menu-item-color;
        .main-title{
            color: rgba($white, 0.6);
        }
    }

    // Enlarge menu
    &[data-sidebar-size="sm"] {
        min-height: 1570px;

        .main-content{
            min-height: 1570px;
        }


        // Side menu
        .vertical-menu {

            // Sidebar Menu
            #sidebar-menu {

                > ul {
                    > li {
                        
                        &:hover {
                            > a {
                                background: lighten($sidebar-dark-bg, 3%);
                                color: $sidebar-dark-menu-item-hover-color;
                                .nav-icon{
                                    fill: $sidebar-dark-menu-item-hover-color;
                                }
                            }

                            >ul {
                                a{
                                    color: $sidebar-dark-menu-sub-item-color;
                                    &:hover{
                                        color: $sidebar-dark-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }

                    ul{
                        background-color: $sidebar-dark-bg;
                    }
                    
                }
            }

            .sidebar-footer{
                display: none;
            }
        }
    }


    .menu-title {
        color: $sidebar-dark-menu-item-color;
    }
}

body[data-layout-mode="dark"] {

    // Enlarge menu
    &[data-sidebar-size="sm"] {

        .vertical-menu {

            // Sidebar Menu
            #sidebar-menu {

                > ul {
                    > li {
                        
                        &:hover {
                            > a {
                                background: lighten($gray-dark-200, 3%);
                            }
                        }
                    }

                    ul{
                        background-color: lighten($gray-dark-200, 3%);
                    }
                    
                }
            }
        }
    }


    .navbar-brand-box{
        background: $gray-dark-200;
    }
    .vertical-menu {
        background: $gray-dark-200;
        border-color: $gray-dark-200;
        &::before{
            background-color: $gray-dark-200;
        }
    }
}





body[data-layout="horizontal"] {
    .main-content {
        margin-left: 0 !important;
    }
}

// Compact Sidebar

body[data-sidebar-size="md"] {
    .navbar-brand-box{
        width: $sidebar-width-sm;
        @media (max-width: 991.98px){
            width: auto;
        }
    }
    .vertical-menu{
        width: $sidebar-width-sm;
        text-align: center;

        .has-arrow:after,
        .badge {
            display: none !important;
        }

        .vertical-menu-btn{
            display: none;
        }
        
    }
    
    .main-content {
        margin-left: $sidebar-width-sm;
    }
    #page-topbar, .footer {
        left: $sidebar-width-sm;
        @media (max-width: 991.98px){
            left: 0;
        }
    }

    #sidebar-menu {
        ul li {
            &.menu-title{
                background-color: darken($sidebar-bg, 2%);
            }
            .menu-item {
                margin-left: 0px;
            }
            a{
                padding: .75rem 1.1rem;
                display: block;
                .nav-icon{
                    width: 20px;
                    height: 20px;
                    display: block;
                    margin: 0 auto;
                    padding-bottom: 0.2em;
                    top: -2px
                }
            }
            ul.sub-menu {
                li a{
                    padding-left: 1.5rem;
                    &:before{
                        display: none;
                    }
                }
            }
        }

        
    }

    &[data-sidebar="dark"] {
        #sidebar-menu {
            ul li {
                &.menu-title{
                    background-color: lighten($sidebar-dark-bg, 6%);
                }
            }
        }
    }

    &:not(.vertical-collpsed) {
        .navbar-header .vertical-menu-btn{
            display: block;
        }
    }
    &[data-sidebar-size="sm"] {
        .main-content {
            margin-left: $sidebar-collapsed-width;
        }
        .vertical-menu {
            #sidebar-menu{
                text-align: left;
                >ul{
                    >li{
                        >a {
                            .nav-icon{
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        #page-topbar, .footer {
            left: $sidebar-collapsed-width;
        }
    }
}



// Light sidebar

body[data-sidebar="light"] {
    .vertical-menu {
        background: $sidebar-bg;
        border-right: 1px solid $sidebar-border-color;
    }

    .navbar-brand-box {
        background-color: $navbar-brand-box-bg;
    }

    .logo {
        line-height: $header-height;
    
        .logo-sm {
            display: none;
        }
    }
    
    .logo-dark {
        display: $display-block;
    }
    
    .logo-light {
        display: $display-none;
    }
    
    .logo-txt {
        color: $dark !important;
    }

    &[data-sidebar-size="sm"] {
    
        .logo {
            span.logo-lg {
                display: none;
            }
    
            span.logo-sm {
                display: block;
            }
        }
    
        // Side menu
        .vertical-menu {
    
    
            // Sidebar Menu
            #sidebar-menu {
    
                > ul {
                    > li {
                        > a {
                            &:hover,
                            &:active,
                            &:focus {
                                color: $sidebar-menu-item-hover-color;
                            }
                        }
    
                        &:hover {
                            > a {
                                color: $primary;
                                background-color: $sidebar-menu-item-active-bg-color;
    
                                .nav-icon{
                                    color: $primary;
                                }
                            }
    
                            >ul {
                                a {
                                    color: $sidebar-menu-sub-item-color;
    
                                    &:hover {
                                        color: $sidebar-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }
    
                    ul {
                        background-color: $sidebar-bg;
                        li.active {
                            a {
                                color: $gray-100;
                            }
                        }
                    }
                }
            }
    
        }
    }
}

// colored sidebar

body[data-sidebar="brand"] {
    background-color: $primary !important;
    .vertical-menu{
        background-color: $primary;
        border-color: $primary;

        @media (min-width: 992px) {
            &::before{
                content: "";
                position: absolute;
                right: -45px;
                width: 45px;
                height: 45px;
                top: 0;
                background-color: $primary;
            }
        }

     

        .vertical-menu-btn{
            color: rgba($white, 0.8);
        }
    }

    @media (min-width: 992px) {
        .hamburger-icon {
            span {
                background-color: rgba($white, 0.8);
            }  
        }
    }

    
    
    .navbar-brand-box{
        background-color: $primary;
        .logo-dark{
            display: none;
        }
        .logo-light{
            display: block;
        }
    }

    #sidebar-menu {
        ul {
            li {
                &.menu-title{
                    color: rgba($white, 0.7);
                }

                a{
                    color: rgba($white, 0.7);
                    .nav-icon{
                        fill: rgba($white, 0.7);
                    }

                    &:hover {
                        color: $white;

                        .nav-icon {
                            fill: $white;
                        }
                    }
                }

                ul.sub-menu {
                    li {
                        a{
                            color: rgba($white, 0.6);

                            &:before{
                                border-color: rgba($white, 0.6);
                            }

                            &:hover {
                                color: $white;
                                &:before{
                                    background-color: $white;
                                    border-color: $white;
                                }
                            }
                        }
                    }
                }

                .badge{
                    background-color: $white;
                }

                &.mm-active {
                    color: $white !important;
            
                    >a {
                        background: none;
                        color: $white !important;

                        &::before{
                            background-color: $sidebar-dark-menu-item-active-color;
                        }

                        .nav-icon {
                            fill: $white !important;
                        }
                    }

                    .mm-show {
                        .mm-active {
                            > a{
                                color: $white !important;
                        
                                &:before{
                                    border-color: $white !important;
                                    background-color: $white !important;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .sidebar-footer{
        .main-title{
            color: rgba($white, 0.8);
        }
        p{
                color: rgba($white, 0.5);
        }
    }

    &[data-topbar="light"] {
        .navbar-brand-box{
            background-color: $primary;
            .logo-dark{
                display: none;
            }
            .logo-light{
                display: block;
            }
        }
    }

    &[data-sidebar-size="sm"] {
        
        .vertical-menu {
            #sidebar-menu{
                >ul{
                    >li{
                        &:hover>a{
                            background-color: lighten($primary, 2%);
                            color: $white;
                            .nav-icon{
                                fill: $white;
                            }
                        }
                    }
                }

                ul{
                    li{
                        &.mm-active {
                            .active{
                                color: $sidebar-menu-item-active-color !important;
                            }
                        }

                        ul.sub-menu {
                            li {
                                a{
                                    &:hover {
                                        color: $sidebar-menu-item-active-color;
                                    }
                                }
                                &.mm-active {
                                    color: $sidebar-menu-item-active-color !important;
                                    > a{
                                        color: $sidebar-menu-item-active-color !important;
                                        i {
                                            color: $sidebar-menu-item-active-color !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .sidebar-footer{
                display: none;
            }
        }

        .hamburger-icon {
            span {
                background-color: $header-item-color;
            }  
        }
    
    }

}
